<template>
  <App
    title="编辑主用车人"
    left-arrow
  >
    <DynamicForm
      ref="form"
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
    />

    <div
      slot="footer"
      class="footer"
    >
      <Button @click="cancel">取消</Button>
      <Button @click="save">确认</Button>
    </div>
  </App>
</template>
<script>
import DynamicForm from '_c/dynamicForm'
import { mainowner } from '@/api/order'
import { Toast } from 'vant'
const options = [
  { label: '是', value: 1 },
  { label: '否', value: 0 }
]
export default {
  name: 'carOwnerEdit',
  components: {
    DynamicForm
  },
  data () {
    return {
      orderInfo: {},
      model: {
        isOwner: 1,
        name: '',
        mobile: '',
        cardNo: ''
      },
      rules: {
        name: [
          { required: true, message: 'Please fill in the name' }
        ],
        mobile: [
          { required: true, message: 'Please fill in the phone number' },
          { pattern: this.$reg.mobile.reg, message: this.$reg.mobile.msg }
        ],
        cardNo: [
          { required: true, message: 'Please fill in the ID number' },
          { pattern: this.$reg.cardNo.reg, message: this.$reg.cardNo.msg }
        ]
      }
    }
  },
  computed: {
    fieldData () {
      return [
        {
          data: [
            { label: '同车辆所有人信息', key: 'isOwner', type: 'select', options: options, listeners: { 'on-select': this.changeIsOwner } },
            { label: '姓名', key: 'name', props: { readonly: !!this.model.isOwner, maxLength: 20 } },
            { label: '手机号', key: 'mobile', props: { readonly: !!this.model.isOwner, maxLength: 11 } },
            { label: '证件号码', key: 'cardNo', props: { readonly: !!this.model.isOwner, maxLength: 20 }, visible: this.orderInfo.purchaseType === '00' }
          ]
        }
      ]
    }
  },
  methods: {
    // 同步车辆所有人
    formatData () {
      const {
        ownerName, ownerPhone, ownerCardNo,
        mainUserName, mainUserPhone, mainUserCardNo
      } = this.orderInfo

      if (this.model.isOwner) {
        this.model.name = ownerName
        this.model.mobile = ownerPhone
        this.model.cardNo = ownerCardNo
      } else {
        this.model.name = mainUserName || ''
        this.model.mobile = mainUserPhone || ''
        this.model.cardNo = mainUserCardNo || ''
      }
    },
    // 取消
    cancel () {
      this.$router.go(-1)
    },
    // 确定
    save () {
      this.$refs.form.validate().then((valid) => {
        if (!valid) return
        /* Toast.loading({
          message: 'loading...',
          forbidClick: true,
          loadingType: 'spinner'
        }) */
        const params = {
          orderNum: this.orderInfo.orderNum,
          channel: this.orderInfo.channel,
          mainUserName: this.model.name,
          mainUserPhone: this.model.mobile,
          mainUserCardNo: this.model.cardNo
        }
        mainowner(params).then(res => {
          if (res.success) {
            Toast('修改成功')
            this.$router.go(-1)
          } else {
            Toast('提交失败请稍后再试')
          }
        }).finally(() => {
          Toast.clear()
        })
      })
    },
    changeIsOwner () {
      this.formatData()
    }
  },
  created () {
    this.orderInfo = this.$route.query
    this.formatData()
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-cell__title {
  width: 3.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .van-cell__value input {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  height: 182px;
  line-height: 98px;
  box-sizing: border-box;
  font-size: 32px;
  background-color: #2b2b31;

  button {
    padding: 0 24px;
    width: 148px;
    height: 56px;
    line-height: 1;
    border-radius: 8px;
    font-size: 28px;
  }

  button:nth-of-type(1) {
    color: #DA3738;
    border: 2px solid #DA3738;
    background-color: transparent;
  }

  button:nth-of-type(2) {
    margin-left: 16px;
    color: #fff;
    background-color: #DA3738;
    border: 0;
  }
}
</style>