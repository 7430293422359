import request from '@/utils/request'
import Api from '@/api/base/api-config'

// 获取基础数据
export const getBaseaData = () => {
  return request({
    method: 'get',
    url: Api.general.basedatas
  })
}

// 车型产品-基础车型
export const getCarType = (params) => {
  return request({
    url: Api.order.cartype,
    method: 'GET',
    params
  })
}

// 车型产品-选装特征
export const getCarFeatures = (params) => {
  return request({
    url: Api.order.carfeatures,
    method: 'GET',
    params
  })
}

// 订单新增
export const createOrder = (data) => {
  return request({
    url: Api.order.createOrder,
    method: 'POST',
    data
  })
}

// 获取城市信息
export const getArea = (params) => {
  return request({
    url: Api.order.area,
    method: 'GET',
    params
  })
}

// 车辆编码及价格
export const getCarprice = (data) => {
  return request({
    url: Api.order.getCarprice,
    method: 'POST',
    data
  })
}

// 车型产品-约束规则
export const getCarconstraint = (params) => {
  return request({
    url: Api.order.carconstraint,
    method: 'GET',
    params
  })
}

// 订单详情
export const getDetail = (params) => {
  return request({
    method: 'GET',
    url: Api.order.detail,
    params
  })
}

// 支付宝和微信二维码支付
export const payment = (data) => {
  return request({
    url: Api.order.payment,
    method: 'POST',
    data
  })
}

// 编辑订单
export const editorder = (params) => {
  return request({
    url: Api.order.editorder,
    method: 'POST',
    data: params
  })
}

// 编辑主用车人
export const mainowner = (params) => {
  return request({
    url: Api.order.mainowner,
    method: 'POST',
    data: params
  })
}

// 查询金融单
export const loanorderlist = (params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.order.loanorderlist}`
  })
}

// 订单改配
export const alterconfigure = (params) => {
  return request({
    url: Api.order.alterconfigure,
    method: 'POST',
    data: params
  })
}

// 申请退款小订订单
export const refund = (params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.order.refund}`
  })
}

// 获取工程车型
export const getCarseries = (params) => {
  return request({
    url: Api.order.getCarseries,
    method: 'GET',
    params
  })
}

// 获取金融方案
export const getLoan = (data) => {
  return request({
    url: Api.order.getLoan,
    method: 'POST',
    data
  })
}

// 创建金融单
export const addloanorder = (data) => {
  return request({
    url: Api.order.addloanorder,
    method: 'POST',
    data
  })
}

// 转大定
export const transtoorder = (data) => {
  return request({
    url: Api.order.transtoorder,
    method: 'POST',
    data
  })
}

// 门店组织List API 及 交付中心
export const getOrgList = (params) => {
  return request({
    url: Api.order.orgList,
    method: 'GET',
    params
  })
}
// 获取勘测单详情
export const surveyDetail = data => {
  return request({
    url: Api.order.surveyDetail,
    method: 'post',
    data
  })
}
// 订单管理 订单列表
export const orderList = (params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.order.orderList}`
  })
}

// 库存列表
export const inventoryList = (page, params) => {
  return request({
    method: 'post',
    data: {
      'page': {
        size: page.size,
        index: page.page
      },
      ...params
    },
    url: Api.order.inventoryList
  })
}

// HandOff
export const handoff = (data) => {
  return request({
    method: 'post',
    url: Api.order.handoff,
    data
  })
}

// 合同保存
export const offlineRegistration = (data) => {
  return request({
    url: Api.order.offlineRegistration,
    method: 'POST',
    data
  })
}

// 合同撤回
export const contractWithdraw = (data) => {
  return request({
    url: Api.order.contractWithdraw,
    method: 'POST',
    data
  })
}
// 创建勘测单时获取服务明星
export const allStart = data => {
  return request({
    url: Api.order.allStart,
    method: 'post',
    data
  })
}
// 创建勘测单
export const createSurvey = data => {
  return request({
    url: Api.order.createSurvey,
    method: 'post',
    data
  })
}
export const leadsPriceOpp = data => {
  return request({
    url: Api.order.leadsPriceOpp,
    method: "PUT",
    data
  })
}
export const shareData = params => {
  return request({
    url: Api.order.shareData,
    method: "GET",
    params
  })
}
export const otdresults = data => {
  return request({
    url: Api.order.otdresults,
    method: "POST",
    data
  })
}